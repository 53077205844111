import { useState } from 'react';
import axios from 'axios';

const API_KEY = 'AIzaSyBtTBT1HrhXS_0k4UXjVVYH07UcX7_6afg'; // Replace with your API key

// Custom Hook to handle Google Sheets operations
function useGoogleSheet() {
    const [sheetUrl, setSheetUrl] = useState('');
    const [sheetNames, setSheetNames] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [columnHeaders, setColumnHeaders] = useState([]);
    const [selectedHeader, setSelectedHeader] = useState('');
    const [columnValues, setColumnValues] = useState({});
    const [processing, setProcessing] = useState(false);
    const [sheetId, setSheetId] = useState("");
    const [mapColumnValues, setMapColumnValues] = useState(null)

    const extractSheetId = (url) => {
        const match = url.match(/\/d\/([a-zA-Z0-9-_]+)\//);
        return match ? match[1] : null;
    };

    const getSheetNames = async (sheetId) => {
        try {
            const response = await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}?key=${API_KEY}`
            );
            return response.data.sheets.map(sheet => sheet.properties.title);
        } catch (error) {
            console.error('Error fetching sheet names:', error);
            return [];
        }
    };

    const getColumnHeaders = async (sheetId, sheetName) => {
        try {
            const response = await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}!1:1?key=${API_KEY}`
            );
            return response.data.values ? response.data.values[0] : [];
        } catch (error) {
            console.error('Error fetching column headers:', error);
            return [];
        }
    };

    const getColumnLetter = (headers, headerName) => {
        const index = headers.indexOf(headerName);
        if (index === -1) return null;

        return String.fromCharCode(65 + index); // 65 is the char code for 'A'
    };

    const getColumnValues = async (sheetId, sheetName, headerName) => {
        try {
            const headers = await getColumnHeaders(sheetId, sheetName);
            const columnLetter = getColumnLetter(headers, headerName);

            if (!columnLetter) {
                console.error(`Header "${headerName}" not found.`);
                return [];
            }

            const range = `${sheetName}!${columnLetter}:${columnLetter}`;
            const response = await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${API_KEY}`
            );

            return response.data.values ? response.data.values.slice(1).flat() : [];
        } catch (error) {
            console.error('Error fetching column values:', error);
            return [];
        }
    };

    const fetchSheetNames = async () => {
        setProcessing(true)

        const sheetId = extractSheetId(sheetUrl);

        setSheetId(sheetId)

        if (sheetId) {
            const names = await getSheetNames(sheetId);
            setSheetNames(names);
            setProcessing(false)
        } else {
            console.error('Invalid Google Sheet URL');
            setProcessing(false)
        }

    };

    const fetchColumnHeaders = async (sheetName) => {
        const sheetId = extractSheetId(sheetUrl);
        if (sheetId) {
            setSelectedSheet(sheetName);
            const headers = await getColumnHeaders(sheetId, sheetName);
            setColumnHeaders(headers);
        }
    };

    const mapHeaderColumnValues = async (header, mapColumn) => {

        setMapColumnValues(prevData => {
            return {
                ...prevData,
                [mapColumn]: header
            }
        })
    };

    const fetchColumnValues = async (header, mapColumn) => {

        const sheetId = extractSheetId(sheetUrl);

        if (sheetId) {
            setSelectedHeader(header);
            const values = await getColumnValues(sheetId, selectedSheet, header);

            setColumnValues(prevData => {
                return {
                    ...prevData,
                    [mapColumn]: values
                }
            });

        }
    };


    return {
        sheetId,
        sheetUrl,
        setSheetUrl,
        sheetNames,
        selectedSheet,
        columnHeaders,
        selectedHeader,
        columnValues,
        fetchSheetNames,
        fetchColumnHeaders,
        fetchColumnValues,
        mapColumnValues,
        mapHeaderColumnValues,
        processing
    };
}

export default useGoogleSheet