import React from 'react'
import Navbar from './Navbar'

function Container({ children }) {
    return (
        <div className="body flex-1 h-screen overflow-y-scroll bg-gray-200">
            <Navbar />
            {children}
        </div>
    )
}

export default Container
