import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/Sidebar";
import { gql, useQuery } from "@apollo/client";
import Group from "../group/Group";
import Container from "../../components/Container";
import Alerts from "../../components/Alerts";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            phoneNumber
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      planType
    }
  }
`;

function GroupManagement() {

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const { data: userProfile, loading: userInfoLoading } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    //GRAPHQL DEVICE QUERY START
    const { refetch } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const selectedDeviceRef = useRef([]);
    const [userProfileInfo, setUserProfileInfo] = useState({})
    const [receiversPhone, setReceiversPhone] = useState([])

    const phoneRef = useRef("");
    const messageRef = useRef("");
    const dateTimeRef = useRef({});
    const fileRef = useRef("");

    //INTERNET CHECKER START
    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    //INTERNET CHECKER END
    const handleContacts = (contacts) => {

        let receiversPhone = contacts.map(contacts => contacts && contacts.phone)

        setReceiversPhone(receiversPhone)
        selectedDeviceRef.current = [];
    }

    const resetAll = () => {

        setStatus("");
        setError("");
        if (phoneRef.current) phoneRef.current.value = "";
        if (messageRef.current) messageRef.current.value = "";
        if (dateTimeRef.current) dateTimeRef.current.value = {};
        if (fileRef.current) fileRef.current.value = "";
    }

    useEffect(() => {
        if (
            receiversPhone &&
            receiversPhone.length > 0
        )
            phoneRef.current.value = receiversPhone.join(",");
    }, [receiversPhone])

    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            error !== "" ||
            status !== ""
        ) {

            setTimeout(() => {
                resetAll()
            }, 5000);
        }
    }, [error, status]);

    useEffect(() => {
        let userProfileData = null;

        if (userProfile) {
            userProfileData = userProfile.user;
            setUserProfileInfo(userProfileData);

            if (
                !(
                    userProfileData.planType &&
                    (
                        userProfileData.planType.includes("WG") ||
                        userProfileData.planType.includes("RC")
                    )
                )
            ) {
                setError("You are not a valid user for this service")
            }
        }
    }, [userProfile, userInfoLoading]);


    return (
        <div className="flex w-screen h-screen bg-white">
            <Sidebar />
            <Container>
                {error !== "" && <Alerts alertType={"Error"}>{error}</Alerts>}
                {
                    userProfileInfo.planType &&
                    (

                        userProfileInfo.planType.includes("WG") ||
                        userProfileInfo.planType.includes("RC")
                    ) && (<Group handleGroupContact={handleContacts} />)
                }

            </Container>
        </div>
    );
}

export default GroupManagement;
