import React, { useEffect, useState } from "react";
import { Link, HashRouter } from "react-router-dom";
import logo from "./logo.png";
import axios from "axios";
const domain = window.location.host;

function Nav() {
  let userUid = localStorage.getItem("user")
    ? localStorage.getItem("user")
    : null;

  const [data, setData] = useState("");

  const getData = () => {
    let data = JSON.stringify({
      "domain": `${domain}`
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://messageapiwl.com/user/userInfoByDomain',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {

        let data = response.data;

        if (data.status !== "error") {

          setData(data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-7">
            <div>
              <Link to="/" className="flex items-center py-4 px-2">
                <img src={data.companyLogo} alt="Logo" className="h-16 w-18 mr-2" />
              </Link>
            </div>

            <div className="hidden md:flex items-center space-x-1">
              <Link
                to="/"
                className="py-4 px-2 text-green-500 border-b-4 border-green-500 font-semibold"
              >
                HOME
              </Link>


              <a
                href="./#contactus"
                className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300"
              >
                CONTACT US
              </a>
            </div>
          </div>
          <div className="self-center">
            {userUid && (
              <Link to="/dashboard" className="text-green-500 font-semibold">
                DASHBOARD
              </Link>
            )}
            {!userUid && (
              <Link to="/login" className="text-green-500 font-semibold">
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
