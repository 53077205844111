import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';

import { gql, useMutation, useQuery } from "@apollo/client";

import Alerts from '../../components/Alerts';
import Sidebar from '../../components/Sidebar'
import { Add, Delete, LinkOffOutlined, MobileFriendly, QrCode } from '@mui/icons-material';
import axios from 'axios'
import Container from '../../components/Container';

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const DEVICE_ADD = gql`
    mutation($device: DeviceInput!){
        deviceCreate(device: $device) {
            device {
            deviceId
            deviceName
            uid
            userId
            }
        }
    }
`;

const DEVICE_DELETE = gql`
    mutation($deviceId: ID!){
        deviceDelete(deviceId: $deviceId) {
            device {
                id
            }
        }
    }
`;

function Device() {
    const navigate = useNavigate();

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };
    const [device, setDevice] = useState({ name: "" });
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState("");
    const [processing, setProcessing] = useState(false);


    //GRAPHQL DEVICE QUERY START
    const { refetch, data, loading } = useQuery(DEVICE_GET, {
        variables: {
            uid: userInfo.uid
        }
    });

    const [
        addDevice
    ] = useMutation(DEVICE_ADD, {
        refetchQueries: [
            DEVICE_GET,
            {
                variables: { uid: userInfo.uid },
            },
        ],
    });

    const [deleteDevice] = useMutation(DEVICE_DELETE, {
        refetchQueries: [
            DEVICE_GET,
            {
                variables: { uid: userInfo.uid },
            }
        ]
    });

    const handleResetConnection = ({ uid, deviceName }) => {

        let config = {
            method: 'get',
            url: `https://mrkgroups.com/device/logout/${uid}/${deviceName}`,
            headers: {}
        };

        axios(config)
            .then(() => {
                refetch();
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleChange = (e) => {
        setDevice(prevData => {
            return { ...prevData, [e.target.name]: e.target.value }
        })
    }

    const handleNewDevice = async (e) => {
        e.preventDefault();
        setProcessing(true);

        if (
            device.name !== "" &&
            userInfo
        ) {
            await addDevice({
                variables: {
                    "device": {
                        "deviceId": `${userInfo.uid}-${device.name.replaceAll(" ", "")}`,
                        "deviceName": device.name.replaceAll(" ", ""),
                        "uid": userInfo.uid,
                        "userId": Number(userInfo.id)
                    }
                }
            });

            setProcessing(false);
            setDevice({ name: "" });

        } else {
            setError("Please input device name")
            setProcessing(false);
        }
    }

    const handleQRRequest = (data) => {

        if (
            userInfo
        ) {

            navigate("/qrcode", { state: data });
        } else {
            setError("Please input device name")
            setProcessing(false);
        }

    }

    //FETCH ALL ADDED DEVICES
    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        if (
            userInfo &&
            userInfo.uid !== ""
        ) {
            refetch();
        }
    }, [userInfo])

    //RESET ALL
    useEffect(() => {
        setTimeout(() => {
            setError("")
        }, [10000])
    }, [error])

    return (

        <div className="flex w-screen">
            <Sidebar />
            <Container>
                <div className='rounded-md p-4 border-2 h-[100%] w-1/2 m-auto'>
                    <div className='relative text-center flex-1'>
                        {
                            devices?.length === 0 && (
                                <div>
                                    <h1 className='text-2xl font-semibold text-green-400 space-y-4'>
                                        {
                                            devices && devices?.length > 0 ? "YOUR DEVICE" : "ADD DEVICE"
                                        }
                                    </h1>
                                    <div className='m-auto w-[90%]'>
                                        {error !== "" && <Alerts alertType="Error">{error}</Alerts>}
                                        <form action="" onSubmit={handleNewDevice}>
                                            <div className='flex justify-center items-center gap-2 m-auto'>
                                                <input className='w-[80%] p-2' type="text" onChange={handleChange} value={device.name} name="name" placeholder='Enter device name' />
                                                <button type="submit" className='px-4 py-1 bg-green-700 text-white rounded-full' disabled={processing}>
                                                    {!processing ? (<span><Add /> Add</span>) : (<span><Add /> Wait...</span>)}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        }


                        {/* <div>
                        <h1 className='text-2xl font-semibold text-gray-400 mt-4 mb-12'>YOUR DEVICES</h1>
                        <div className='m-auto w-[50%]'>
                            {error !== "" && <Alerts alertType="Error">{error}</Alerts>}
                            <form action="" onSubmit={handleNewDevice}>
                                <div className='space-y-2 flex justify-center items-center space-x-2 m-auto'>
                                    <input className='w-[50%]' type="text" onChange={handleChange} value={device.name} name="name" placeholder='Enter device name' />
                                    <button type="submit" className='px-4 py-1 bg-green-700 text-white rounded-full mt-4' disabled={processing}>
                                        {!processing ? (<span><Add /> Add</span>) : (<span><Add /> Wait...</span>)}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div> */}


                        <div className='m-auto w-[90%]'>
                            {
                                devices && devices?.length > 0 && (
                                    <div className='w-[90%] m-auto'>
                                        <h2 className='mt-4 border-dotted border-b-2 text-green-400 border-b-blue-500 font-semibold font-serif text-xl tracking-widest px-4 py-2 space-y-2'>DEVICE LIST</h2>

                                        <div className='py-2  m-auto w-[40%]'>
                                            <ul className='flex justify-center'>
                                                <li className='flex justify-start items-center mr-4'>
                                                    <p className='w-4 h-4 bg-green-400 mr-1'></p>
                                                    CONNECTED
                                                </li>
                                                <li className='flex justify-start items-center'>
                                                    <p className='w-4 h-4 bg-gray-400 mr-1'></p>
                                                    DISCONNECTED
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='py-2'>
                                            <p className='text-green-400 font-serif text-sm mt-6'>Note:- Click on QR icon to connect device</p>
                                        </div>

                                        <ul className='w-full m-auto h-[300px] overflow-y-scroll no-scrollbar'>
                                            {
                                                devices.map((device, index) => {
                                                    return (
                                                        <li className={` ${device.isConnected ? "bg-green-500" : "bg-gray-400"} flex justify-between items-center  px-2 py-1 rounded-xl mt-2 text-white`} key={device.deviceName}>
                                                            <>
                                                                <button onClick={() => {
                                                                    handleQRRequest({ ...userInfo, deviceName: device.deviceName })
                                                                }}>{device.isConnected ? <MobileFriendly /> : <QrCode />}</button>
                                                                <button className='cursour-pointer flex-1'>{device.deviceName}</button>
                                                                {device.isConnected && (<button onClick={() => handleResetConnection({ uid: userInfo.uid, deviceName: device.deviceName })}><LinkOffOutlined /></button>)}
                                                                {!device.isConnected && (<button onClick={() => deleteDevice({ variables: { deviceId: device.deviceId } })}><Delete /></button>)}
                                                            </>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                            {
                                devices && devices?.length === 0 && (
                                    <p className='text-md font-serif py-2 text-red-400'>No Device found!</p>
                                )
                            }
                        </div>

                    </div>
                </div>
            </Container>

        </div>

    )
}

export default Device