import axios from 'axios'
import { useEffect, useState } from 'react';

function useGroup() {
    const [successGroup, setSuccessGroup] = useState("")
    const [errorGroup, setErrorGroup] = useState("")

    useEffect(() => {
        setTimeout(() => {
            setSuccessGroup("")
            setErrorGroup("")
        }, 2500)
    }, [successGroup, errorGroup])

    const createGroup = async ({ id, deviceName, groupName, participants }) => {
        try {
            let data = JSON.stringify({
                "groupName": groupName,
                "participants": participants
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://mrkgroups.com/group/createGroup/${id}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let response = await axios.request(config)
            response = response.data

            if (response.status === "success") {
                console.log("response.message", response.message)
                setSuccessGroup(response.message)
            } else {
                setErrorGroup(response.message)
            }

            return response

        } catch (error) {
            console.log("61 - error", error.response.data.message)

            if (error.response && error.response.data && error.response.data.message) {
                setErrorGroup(error.response.data.message)
            } else {
                setErrorGroup("An unknown error occurred.");
            }
        }
    }

    const updateGroup = () => {
        // 
    }

    const deletePhoneGroup = async ({ userId, deviceName, groupId, user }) => {

        try {

            let data = JSON.stringify({
                "groupId": groupId,
                "user": user
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://mrkgroups.com/group/deleteGroup/${userId}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let response = await axios.request(config)

            response = response.data

            if (response.status === "success") {
                setSuccessGroup(response.message)
            } else {
                setErrorGroup(response.message)
            }

            return response

        } catch (error) {
            console.log("61 - error", error.response.data.message)

            if (error.response && error.response.data && error.response.data.message) {
                setErrorGroup(error.response.data.message)
            } else {
                setErrorGroup("An unknown error occurred.");
            }
        }


    }

    const addGroupParticipant = async ({ id, deviceName, groupId, participantNumber }) => {

        try {
            let data = JSON.stringify({
                "groupId": groupId,
                "participantNumber": participantNumber
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://mrkgroups.com/group/addParticipant/${id}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let response = await axios.request(config)
            return response.data
        } catch (error) {
            console.log("94 - error", error.response.data.message)

            if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message
            } else {
                return "An unknown error occurred.";
            }
        }
    }

    const updateGroupParticipant = () => {
        // 
    }

    const deleteGroupParticipant = async ({ id, deviceName, groupId, participantNumber }) => {
        try {
            let data = JSON.stringify({
                "groupId": groupId,
                "participantNumber": participantNumber
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://mrkgroups.com/group/deleteParticipant/${id}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let response = await axios.request(config)
            return response.data
        } catch (error) {
            console.log("128 - error", error.response.data.message)

            if (error.response && error.response.data && error.response.data.message) {
                return error.response.data.message
            } else {
                return "An unknown error occurred.";
            }
        }
    }

    const migrateGroupParticipant = () => {
        // 
    }

    return {
        createGroup,
        updateGroup,
        deletePhoneGroup,
        addGroupParticipant,
        updateGroupParticipant,
        deleteGroupParticipant,
        migrateGroupParticipant,
        successGroup,
        errorGroup
    }
}

export default useGroup