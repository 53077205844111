import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable";
import Container from "../../components/Container";
import { ShoppingBag } from "lucide-react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const GET_PLAN = gql`
  query getPlan($planFor: String!) {
    plans(planFor: $planFor) {
      id
      name
      planFor
      rate
      amount
      wallet
      credits
      validity
    }
  }
`;

export default function Plans() {
  const navigate = useNavigate()

  const columns = [
    { field: "name", headerName: "Plan Name", minWidth: 170, align: "left" },
    {
      field: "rate",
      headerName: "Rate",
      minWidth: 130,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 130,
      align: "center",
    },
    {
      field: "wallet",
      headerName: "Wallet",
      minWidth: 130,
      align: "center",
    },
    {
      field: "credits",
      headerName: "Credits",
      minWidth: 130,
      align: "right",
    },
    {
      field: "validity",
      headerName: "Validity",
      minWidth: 130,
      align: "center",
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={< ShoppingBag />}
          label="Shopping"
          onClick={
            () => navigate(
              "/order", { state: { ...params.row, planType: params.row.name } }
            )
          }
        />
      ]
    },
  ];

  const [userPlansData, setUserPlansData] = useState(null);

  const {
    data: userPlans,
    loading: userPlanLoading,
    error: userPlanError,
  } = useQuery(GET_PLAN, {
    variables: { planFor: "User" },
  });

  //Userplan Fetch End

  useEffect(() => {

    if (userPlans) {
      let uPlans = userPlans.plans;

      setUserPlansData(uPlans)
    };
  }, [userPlans, userPlanLoading]);


  return (
    <div className="flex w-screen h-screen ">
      <Sidebar />
      <Container>
        <div>
          <p className="text-green-400  flex justify-center items-center py-4 text-xl font-semibold">BUY NEW PLAN</p>
        </div>
        <div className="grid grid-cols-12 gap-2  p-4 pt-0 h-screen overflow-y-scroll no-scrollbar">
          <div className="col-span-12 ">
            {
              userPlansData &&
                userPlansData.length > 0 ? (
                <DataTable columns={columns} data={userPlansData} />
              ) : (
                <div className="h-screen w-full flex justify-center items-center">
                  <p className="text-xl font-semibold text-red-500">
                    no records found
                  </p>
                </div>
              )}
          </div>
        </div>
      </Container>
    </div>
  );
}
