import useExcelSheet from "../hook/useExcelSheet";

// Component for selecting a sheet
function SheetSelector({ sheetNames, onSelect }) {
    return (
        <select onChange={(e) => onSelect(e.target.value)}>
            <option>Select a Sheet</option>
            {sheetNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
            ))}
        </select>
    );
}

// Component for selecting a header
function HeaderSelector({ columnHeaders, onSelect, mapColumn }) {
    return (
        <select onChange={(e) => onSelect(e.target.value, mapColumn)}>
            <option>Select a Column Header</option>
            {columnHeaders.map((header, index) => (
                <option key={index} value={header}>{header}</option>
            ))}
        </select>
    );
}

// Main Component
function ExcelSheetHandler({ setExcelColumnValues }) {
    const {
        handleFileUpload,
        sheetNames,
        columnHeaders,
        columnValues,
        fetchColumnHeaders,
        fetchColumnValues
    } = useExcelSheet();

    setExcelColumnValues(columnValues || null)

    return (
        <div className="grid grid-cols-1 my-4 w-full">
            <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
            />

            {
                sheetNames.length > 0 &&
                (
                    <div className="grid grid-cols-1 my-4" >
                        <div className="grid grid-cols-2 my-4">
                            <div>
                                <p className="text-xl font-thin text-gray-800 mb-2">
                                    Select Page:
                                </p>
                            </div>
                            <div>
                                {
                                    sheetNames.length > 0 && (
                                        <SheetSelector sheetNames={sheetNames} onSelect={fetchColumnHeaders} />
                                    )
                                }
                            </div>
                        </div>
                        {
                            columnHeaders.length > 0 && (
                                <div>
                                    <div className="grid grid-cols-2 my-2">
                                        <label
                                            htmlFor="phones-col"
                                            className="text-xl font-thin text-gray-800">
                                            Name:
                                        </label>
                                        <HeaderSelector columnHeaders={columnHeaders} onSelect={fetchColumnValues} mapColumn="name" />
                                    </div>
                                    <div className="grid grid-cols-2 my-2">
                                        <label
                                            htmlFor="phones-col"
                                            className="text-xl font-thin text-gray-800">
                                            Phone:
                                        </label>
                                        <HeaderSelector columnHeaders={columnHeaders} onSelect={fetchColumnValues} mapColumn="phone" />
                                    </div>
                                    <div className="grid grid-cols-2 my-2">
                                        <label
                                            htmlFor="messages-col"
                                            className="text-xl font-thin text-gray-800">
                                            Message:
                                        </label>
                                        <HeaderSelector columnHeaders={columnHeaders} onSelect={fetchColumnValues} mapColumn="message" />
                                    </div>

                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

export default ExcelSheetHandler;