import React, { useEffect, useState } from "react";
import illustration from './illustration.png'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Alerts from "../../components/Alerts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Google } from '@mui/icons-material';
import Logo from "./logo.png"
const domain = window.location.host;

const CREATE_USER = gql`
  mutation CreateUser($user: UserInput!) {
    userCreate(user: $user) {
      userErrors {
        message
      }
      user {
        name
        uid
        email
      }
    }
  }
`;

const GET_USER_BY_MAIL = gql`
  query($email: String!){
    userByEmail(email: $email) {
      name
    }
  }
`;

const GET_USER_BY_MOBILE = gql`
  query($mobile: String!){
    userByMobile(mobile: $mobile) {
      name
    }
  }
`;

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    employeeCode: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const navigate = useNavigate();

  const handleFormInput = (e) => {
    const id = e.target.name;
    const value = e.target.value;

    setFormData((prevData) => {
      return { ...prevData, [id]: value };
    });
  };

  const [getUserByEmail] = useLazyQuery(GET_USER_BY_MAIL)

  const [getUserByMobile] = useLazyQuery(GET_USER_BY_MOBILE)

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      let {
        userCreate: { user },
      } = data;
      // dispatch({ type: "SIGNUP", payload: user });

      // localStorage.setItem("user", JSON.stringify({ ["uid"]: user.uid }));
      handleWelcome();
      setSuccess("Account has been created")

    },
    onError: (error) => {
      if (
        error.message === "Unique constraint failed on the fields: (`email`)"
      ) {
        setError("Email is already registered");
      } else {
        setError(error.message);
      }

    },
  });

  const handleWelcome = () => {

    let data = JSON.stringify({
      "subject": "Account Created",
      "fromEmail": "info@messageapi.in",
      "toEmail": formData.email,
      "html": `
<!doctype html>
<html lang="en-US">
<head>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <title>New Account Email </title>
    <meta name="description" content="New Account Email .">
    <style type="text/css">
        a:hover {text-decoration: underline !important;}
    </style>
</head>

<body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
    <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
        style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
        <tr>
            <td>
                <table style="background-color: #f2f3f8; max-width:670px; margin:0 auto;" width="100%" border="0"
                    align="center" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <a href="https://mrkgroups.com/" title="logo" target="_blank">
                            <img width="60" src="https://mrkgroups.com/static/media/logo.43a695fe1a86c9e871c5.png" title="logo" alt="logo">
                          </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                style="max-width:670px; background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="padding:0 35px;">
                                        <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Get started
                                        </h1>
                                        <p style="font-size:15px; color:#455056; margin:8px 0 0; line-height:24px;">
                                            Your account has been created on the MRK. Below are your credentials, <br></p>
                                        <span
                                            style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                        <p
                                            style="color:#455056; font-size:18px;line-height:20px; margin:0; font-weight: 500;">
                                            <strong
                                                style="display: block;font-size: 13px; margin: 0 0 4px; color:rgba(0,0,0,.64); font-weight:normal;">Username</strong>${formData.email.toLowerCase().trim()}
                                            <strong
                                                style="display: block; font-size: 13px; margin: 24px 0 4px 0; font-weight:normal; color:rgba(0,0,0,.64);">Password</strong>${formData.password}
                                        </p>

                                        <a href="https://mrkgroups.com/login"
                                            style="background:#20e277;text-decoration:none !important; display:inline-block; font-weight:500; margin-top:24px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Login
                                            to your Account</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${domain}</strong> </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>

</html>`,
      "attachments": []
    });

    let config = {
      method: 'post',
      url: `https://mrkgroups.com/mail/sendmail`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisibility);
  };

  const handleMerchantExpiry = (e) => {
    e.preventDefault();

    handleSignup();

  }

  const handleSignup = async () => {

    setIsPending(true);

    //CHECK MAIL IS AVAILABLE
    let isMailAvailable = await getUserByEmail({
      variables: {
        email: formData.email.toLowerCase().trim()
      }
    });

    //CHECK MOBILE IS AVAILABLE
    let isMobileAvailable = await getUserByMobile({
      variables: {
        mobile: formData.mobile.trim()
      }
    });

    if (isMailAvailable.data) {
      setError("Duplicate email is not allowed :(")

      return
    }

    if (isMobileAvailable.data) {
      setError("Duplicate mobile is not allowed :(")

      return
    }

    if (formData.password !== formData.confirm_password) {
      setError("Password not matched");

    } else {
      //SAVE DATA IN DATABASE START
      let uuid = uuidv4();

      createUser({
        variables: {
          user: {
            uid: uuid.replace(/[^a-zA-Z0-9]/g, ""),
            employeeCode: formData.employeeCode,
            name: formData.name,
            email: formData.email.toLowerCase().trim(),
            mobile: formData.mobile.trim(),
            password: formData.password,
            totalMessages: 30,
            totalMessagesSent: 0,
            userType: "User",
            planType: "DEMO",
            expireInDays: 3,
            isActive: true,
          },
        },
      });

      //SAVE DATA IN DATABASE END
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccess("");
      setIsPending(false);
    }, 5000)
  }, [error, success])

  return (
    <div className="min-h-screen flex">
      {/* Left side - Login Form */}
      <div className="w-1/2 bg-blue-50 flex justify-center items-center p-8 relative">
        {/* Illustration Image */}
        <div className="text-center">
          <img
            src={illustration} // Replace with your image
            alt="Illustration"
            className="mx-auto mb-6"
          />
          {/* <div className="text-right absolute top-8 right-8">
            <h3 className="text-lg font-bold">New Update Available</h3>
            <p className="text-gray-500">We have added some new awesome features</p>
            <a
              href="/learn-more"
              className="text-blue-400 hover:underline mt-2 inline-block"
            >
              Learn More
            </a>
          </div> */}
        </div>
      </div>

      {/* Right side - Image and Update Info */}

      <div className="w-1/2 bg-white flex flex-col justify-center items-center p-8">
        <div className="w-3/4 max-w-md">
          {/* Logo */}
          <div className="text-center mb-6">
            <img
              src={Logo} // Replace this with your logo
              alt="MRK Group"
              className="mx-auto"
              style={{ height: '50px' }}
            />
          </div>
          {/* Welcome Text */}
          <h2 className="text-3xl font-bold text-center mb-6 uppercase text-green-400">Welcome Back</h2>

          {/* Google Login Button */}
          <button className="w-full bg-blue-500  border border-gray-300 text-white py-2 rounded-lg flex items-center justify-center gap-2 mb-6">
            <Google />
            <span>Sign up with Google</span>
          </button>

          {/* Divider */}
          <div className="flex items-center mb-4">
            <hr className="flex-grow border-gray-300" />
            <span className="mx-2 text-gray-400">OR SIGN UP WITH EMAIL</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          {/* Signup Form */}
          <form onSubmit={handleMerchantExpiry}>

            <div>
              <label htmlFor="name">Name</label>
              <div className="mt-1">
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={(e) => handleFormInput(e)}
                  required
                  className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label htmlFor="email">Email</label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="mobile">Mobile</label>
                <div className="mt-1">
                  <input
                    id="mobile"
                    name="mobile"
                    type="number"
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative mt-1">
                  <input
                    id="password"
                    name="password"
                    type={passwordVisibility ? "text" : "password"}
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                  {passwordVisibility ? (
                    <VisibilityOffIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handlePasswordVisibility()}
                    />
                  ) : (
                    <VisibilityIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handlePasswordVisibility()}
                    />
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="confirm_password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <div className="relative mt-1">
                  <input
                    id="confirm_password"
                    name="confirm_password"
                    type={confirmPasswordVisibility ? "text" : "password"}
                    onChange={(e) => handleFormInput(e)}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg mb-4"
                  />
                  {confirmPasswordVisibility ? (
                    <VisibilityOffIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handleConfirmPasswordVisibility()}
                    />
                  ) : (
                    <VisibilityIcon
                      className="absolute top-2 right-2 cursor-pointer"
                      onClick={() => handleConfirmPasswordVisibility()}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center my-4">
              <input
                id="terms-and-privacy"
                name="terms-and-privacy"
                type="checkbox"
                required
              />
              <label
                htmlFor="terms-and-privacy"
                className="ml-2 block text-sm text-gray-900"
              >
                I agree to the
                <a href="/aggrement" className="mx-2 text-blue-400 hover:text-blue-500">
                  Terms
                </a>
                and
                <a href="/" className="ml-2 text-blue-400 hover:text-blue-500">
                  Privacy Policy
                </a>
                .
              </label>
            </div>
            <div className="text-center">
              {!isPending && (
                <button
                  type="submit"
                  className="w-full rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              )}
              {isPending && (
                <button
                  type="submit"
                  className="w-full rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  disabled
                >
                  Wait....
                </button>
              )}
              {error && <Alerts alertType="Error">{error}</Alerts>}

            </div>
          </form>

          {/* Signup Link */}
          <p className="text-center mt-6">
            Already account?{' '}
            <Link to="/login" className="text-blue-400 hover:underline">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
