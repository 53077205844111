import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import Emojis from "../../util/Emojis";
import { gql, useQuery } from "@apollo/client";
import CronStringGenerator from "../../util/CronStringGenerator";
import GoogleSheetHandler from "../../components/GoogleSheetHandler";
import ExcelSheetHandler from "../../components/ExcelSheetHandler";
import ExcelSheetView from "../googleSheet/ExcelSheetView";
import Container from "../../components/Container";
import { View } from "lucide-react";
import Alerts from "../../components/Alerts";
import ReactQuill from "react-quill";

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      planType
    }
  }
`;

const DEVICE_GET = gql`
  query($uid: String!){
      devicesByUID(uid: $uid) {
          id
          deviceId
          deviceName
          uid
          userId
          isConnected
          createdAt
          updatedAt
      }
}`;

function SheetMessage() {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  const [selectedFile, setSelectedFile] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [isScheduleProcess, setIsScheduleProcess] = useState(false);
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isScheduleClicked, setIsScheduleClicked] = useState(false);
  const [sentMessage, setSentMessage] = useState(0);
  const [totalMessage, setTotalMessage] = useState(0);
  const [sheetTypeSelection, setSheetTypeSelection] = useState("")
  const [excelColumnValues, setExcelColumnValues] = useState(null)
  const [devices, setDevices] = useState([]);
  const [excelSheetVisible, setExcelSheetVisible] = useState(false)
  const [excelSheetValues, setExcelSheetValues] = useState([])
  const [userProfileInfo, setUserProfileInfo] = useState({})

  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  const selectedDeviceRef = useRef([]);
  const cursorRef = useRef(null);
  const phoneRef = useRef(null);
  const quillRef = useRef(null);
  const dateTimeRef = useRef({});
  const fileRef = useRef(null);

  let start = 0;
  let phones = [];

  //GRAPHQL DEVICE QUERY START
  const { data: userProfile, loading: userInfoLoading } = useQuery(USER_GET, {
    variables: { uid: userInfo.uid },
  });

  const { refetch, data, loading } = useQuery(DEVICE_GET, {
    variables: { uid: userInfo.uid },
  });

  const handleMessage = (e) => {
    setMessage((prevData) => {
      return { ...prevData, message: "", [e.target.name]: e.target.value };
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const formatWhatsAppMessage = (message, name = "") => {

    if (name === "") {
      //Remove all placeholder word from string
      message = message.replace(/\{\{[^}]+\}\}/g, "").replace(/\s{2,}/g, " ").trim();
    } else {
      // Replace {{name}} placeholder with the actual name
      message = message.replace('{{name}}', name);
    }

    // Replace <em> tags with WhatsApp italic formatting
    message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

    // Replace <strong> tags with WhatsApp bold formatting
    message = message.replace(/<strong>(.*?)<\/strong>/g, '*$1*');

    // Replace <u> tags (underline) with plain text or you can use another formatting
    message = message.replace(/<u>(.*?)<\/u>/g, '_$1_'); // Using _ for emphasis since underline isn't supported

    // Ensure <p> tags add new lines
    message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
    message = message.replace(/<p>/g, ''); // Remove opening <p> tags

    // Convert ordered list <ol> into plain numbered list with a newline before it
    message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
      let formattedList = '\n'; // Add newline before the list
      let counter = 1;
      listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
        formattedList += `${counter}. ${listItem}\n`; // Add numbering
        counter++;
      });
      return formattedList;
    });

    // Convert unordered list <ul> into plain bullet list with a newline before it
    message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
      let formattedList = '\n'; // Add newline before the list
      listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
        formattedList += `• ${listItem}\n`; // Add bullet points
      });
      return formattedList;
    });

    // Remove any other remaining HTML tags
    message = message.replace(/<[^>]*>/g, '');

    return message.trim(); // Trim any trailing newlines or spaces
  }

  const sendScheduleFileMessage = (deviceName, phone) => {
    const apiURL = `https://mrkgroups.com/schedule/addFileMessage`;
    const formData = new FormData();

    formData.append("id", userInfo.uid);
    formData.append("uid", userInfo.uid);
    formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
    formData.append("deviceName", selectedDeviceRef.current[0]);
    formData.append("file", selectedFile);
    formData.append("dateTime", dateTimeRef.current.value);
    formData.append("phones", phone);

    if (message.messageType === "defaultMessage") {

      formData.append("message", message.message);
    } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {
      formData.append("message", excelSheetValues[start].message);
    } else {
      setError("Message is not valid or it is blank")
      return
    }

    fetch(apiURL, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        let msgResp = result.message;
        let msgStatus = result.status;

        if (msgStatus !== "error") {
          ++start;
          setStatus(msgResp);

          //Set Msg sent count
          setSentMessage(start);

          if (phones && phones.length > start) {
            sendScheduleFileMessage(deviceName, phones[start]);
          } else {
            setStatus(msgResp);
          }
        } else {
          setError(msgResp);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const sendScheduleMessage = () => {

    let indexRef = 0;

    setIsScheduleProcess(true);

    let apiURL = `https://mrkgroups.com/schedule/addMessage`;

    if (
      excelSheetValues &&
      excelSheetValues.length > 0
    ) {
      if (isSelected) {
        if (excelSheetValues && excelSheetValues.length > 0) {
          setSentMessage(start);
          phones = phoneRef.current.value.trim().split(",")
          sendScheduleFileMessage(selectedDeviceRef.current[indexRef], phones[start]);
        } else {
          setError("Phone number is missing")
        }
      } else {
        try {
          const sendMsg = async (deviceName, phone) => {
            let data = {};

            if (message.messageType === "defaultMessage") {
              data = JSON.stringify({
                id: userInfo.uid,
                phones: phone,
                message: message.message,
                dateTime: dateTimeRef.current.value,
                cronString: CronStringGenerator(dateTimeRef.current.value),
                uid: userInfo.uid,
                deviceName: deviceName,
                file: ""
              });
            } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {
              data = JSON.stringify({
                id: userInfo.uid,
                phones: phone,
                message: excelSheetValues[start].message,
                dateTime: dateTimeRef.current.value,
                cronString: CronStringGenerator(dateTimeRef.current.value),
                uid: userInfo.uid,
                deviceName: deviceName,
                file: ""
              });
            } else {
              setError("Message is not valid or it is blank")
              return
            }

            let config = {
              method: "POST",
              url: apiURL,
              headers: {
                "Content-type": "application/json",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                let msgResp = response.data.message;
                let msgStatus = response.data.status;

                if (msgStatus !== "error") {

                  setStatus(msgResp);
                  ++start;

                  //Set Msg sent count
                  setSentMessage(start);

                  if (phones && phones.length > start) {
                    sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
                  } else {
                    setStatus(msgResp);
                    return
                  }
                } else {
                  setError(msgResp);
                }
              })

          }

          if (excelSheetValues && excelSheetValues.length > 0) {
            setSentMessage(start);
            phones = phoneRef.current.value.trim().split(",")
            sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
          } else {
            setError("Phone number is missing")
          }
        } catch (error) {
          setError("You are not authorized to make this request");
        }
      }

    } else {
      setError("Phone is not valid");
    }
  }

  const sendFileMessage = (deviceName, phone) => {
    const apiURL = `https://mrkgroups.com/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("phone", phone);

    if (message.messageType === "defaultMessage") {
      formData.append("message", message.message);
    } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {
      formData.append("message", excelSheetValues[start].message);
    } else {
      setError("Message is not valid or it is blank")
      return
    }

    fetch(apiURL, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        let msgResp = result.message;
        let msgStatus = result.status;

        if (msgStatus !== "error") {
          ++start;
          setStatus(msgResp);

          //Set Msg sent count
          setSentMessage(start);

          if (phones && phones.length > start) {
            sendFileMessage(deviceName, phones[start]);
          } else {
            setStatus(msgResp);
          }
        } else {
          setError(msgResp);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  const sendMessage = (e) => {

    e.preventDefault();

    let indexRef = 0;

    setIsClicked(true);

    let apiURL = `https://mrkgroups.com/chat/sendMessage`;

    if (selectedDeviceRef.current && selectedDeviceRef.current.length === 0) {
      setError("Please select atleast one device");
      return
    }

    if (
      excelColumnValues
    ) {

      if (isSelected) {

        if (excelSheetValues && excelSheetValues.length > 0) {
          setSentMessage(start);
          phones = phoneRef.current.value.trim().split(",")
          sendFileMessage(selectedDeviceRef.current[indexRef], phones[start]);
        } else {
          setError("Phone number is missing")
        }
      } else {

        const sendMsg = (deviceName, phone) => {
          let data = {};

          if (excelSheetValues.length > 0 && excelSheetValues[start].message) {

            if (message.messageType === "defaultMessage") {

              data = JSON.stringify({
                "id": userInfo.uid,
                "name": deviceName,
                "phone": phone.toString(),
                "message": formatWhatsAppMessage(message.message)
              });

            } else if (excelSheetValues[start].message && excelSheetValues[start].message !== "") {

              data = JSON.stringify({
                "id": userInfo.uid,
                "name": deviceName,
                "phone": phone.toString(),
                "message": formatWhatsAppMessage(excelSheetValues[start].message, excelSheetValues[start].name)
              });

            } else {
              setError("Message is not valid or it is blank")
              return
            }

          } else {
            data = JSON.stringify({
              "id": userInfo.uid,
              "name": deviceName,
              "phone": phone.toString(),
              "message": message
            });
          }

          var config = {
            method: "POST",
            url: apiURL,
            headers: {
              "Content-type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              let msgResp = response.data.message;
              let msgStatus = response.data.status;

              if (msgStatus !== "error") {

                setStatus(msgResp);
                ++start;

                //Set Msg sent count
                setSentMessage(start);

                if (phones && phones.length > start) {
                  sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
                } else {
                  setStatus(msgResp);
                  return
                }
              } else {
                setError(msgResp);
              }
            })
            .catch(function (error) {
              setError(error.response.data.message);
            });
        }

        if (excelSheetValues && excelSheetValues.length > 0) {
          setSentMessage(start);
          phones = phoneRef.current.value.trim().split(",")
          sendMsg(selectedDeviceRef.current[indexRef], phones[start]);
        } else {
          setError("Phone number is missing")
        }

      }

    } else {
      setError("Phone is not valid");
    }
  };

  const handleSelectedDevice = (e) => {

    if (e.target.value) {
      selectedDeviceRef.current.push(e.target.value)
    } else {
      selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
    }

    if (selectedDeviceRef.current.length > 0) {
      setIsDeviceSelected(true)
    } else {
      setIsDeviceSelected(false)
    }
  }

  const handleSheetTypeSelection = (e) => {
    if (e.target.value) {

      if (
        userProfileInfo.planType &&
        (
          userProfileInfo.planType.includes("UL") ||
          userProfileInfo.planType.includes("WG") ||
          userProfileInfo.planType.includes("GS") ||
          userProfileInfo.planType.includes("RC")
        )
      ) {

        setSheetTypeSelection(e.target.value)
      } else {

        setError("You are not a valid user for this service")
      }

    }
  }

  const handleFileAttachmentReset = () => {
    if (fileRef.current) {
      fileRef.current.value = "";
      fileRef.current.type = "text";
      fileRef.current.type = "file";
    }
  };

  const handleQuillChange = (value) => {
    setMessage((prevData) => {
      return { ...prevData, "message": value };
    });
  };

  const resetAll = () => {

    setSelectedFile("")
    setIsSelected(false)
    setIsScheduleProcess(false)
    setIsClicked(false)
    setIsScheduleClicked(false)
    setSentMessage(0)
    setTotalMessage(0)
    setSheetTypeSelection("")
    setExcelColumnValues(null)


    if (selectedDeviceRef.current && selectedDeviceRef.current?.value) selectedDeviceRef.current.value = "";
    if (cursorRef.current && cursorRef.current?.value) cursorRef.current.value = "";
    if (phoneRef.current && phoneRef.current.value) phoneRef.current.value = "";
    if (quillRef.current && quillRef.current.value) quillRef.current.value = "";
    if (dateTimeRef.current && dateTimeRef.current.value) dateTimeRef.current.value = "";
    if (fileRef.current && fileRef.current.value) fileRef.current.value = "";

    refetch({ uid: userInfo.uid })
  }

  useEffect(() => {
    let userProfileData = null;

    if (userProfile) {
      userProfileData = userProfile.user;
      setUserProfileInfo(userProfileData);
    }
  }, [userProfile, userInfoLoading]);

  useEffect(() => {
    if ((status !== "" || error !== "") && sentMessage === totalMessage) {
      setTimeout(() => {
        resetAll();
        handleFileAttachmentReset();
        setMessage("")
        setError("")
        setStatus("")
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, status, sentMessage]);

  //FETCH ALL ADDED DEVICES
  useEffect(() => {
    let devicesByUID = "";
    if (data) {
      devicesByUID = data.devicesByUID;
      setDevices(devicesByUID);
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      status !== "" || error !== ""
    ) {
      setTimeout(() => {
        setIsClicked(false)
        resetAll()
        document.getElementById("sheetmessage").reset();
      }, 2500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, status]);

  useEffect(() => {
    let userProfileData = null;

    if (userProfile) {
      userProfileData = userProfile.user;
      setUserProfileInfo(userProfileData);

      if (
        !(
          userProfileData.planType &&
          (
            userProfileData.planType.includes("WG") ||
            userProfileData.planType.includes("GS") ||
            userProfileData.planType.includes("RC")
          )
        )
      ) {
        setError("You are not a valid user for this service")
      }
    }
  }, [userProfile, userInfoLoading]);

  const handleSelectionChange = useCallback(() => {
    const editor = quillRef.current.getEditor(); // Get Quill editor instance
    const selection = editor.getSelection();

    if (selection) {
      cursorRef.current = selection.index; // Update cursor position

    }

  }, []);

  useEffect(() => {
    const editor = quillRef.current.getEditor();
    editor.on('selection-change', handleSelectionChange);

    return () => {
      editor.off('selection-change', handleSelectionChange);
    };

  }, [handleSelectionChange]);

  const insertEmoji = (emoji) => {
    const editor = quillRef.current.getEditor();
    const cursorPosition = cursorRef.current;

    console.log("emoji", emoji)
    console.log("cursorPosition", cursorPosition)

    if (cursorPosition !== undefined) {
      editor.insertText(cursorPosition, emoji);
    }
  };

  useEffect(() => {
    let phones = []
    if (excelSheetValues && excelSheetValues.length > 0) {
      phones = excelSheetValues.map(value => value.phone)
      phoneRef.current.value = phones
      setTotalMessage(phoneRef.current.value.trim().split(",").length)
    }
  }, [excelSheetValues])

  return (
    <div className="flex w-screen h-screen bg-white">
      <Sidebar />
      <Container>

        <form id="sheetmessage">
          <div className="grid grid-cols-12 gap-2 px-4 pt-8 pl-8">

            <div className="col-span-9 h-screen overflow-y-scroll">
              <div className="my-2">
                {
                  status !== "" ? (
                    <p className="text-center text-sm tracking-normal text-green-500 ">
                      {
                        status && (<Alerts alertType="Info">{status}</Alerts>)
                      }
                    </p>
                  ) : error !== "" ? (
                    <p className="text-center text-sm tracking-normal text-red-500">
                      {
                        error && (<Alerts alertType="Error">{error}</Alerts>)
                      }
                    </p>
                  ) : (
                    ""
                  )
                }
              </div>

              <div>
                <p className="text-green-400  flex justify-center items-center pb-4 text-xl font-semibold">IMPORT SHEET</p>
              </div>
              <div className="w-10/12 flex flex-col space-y-4 ">

                <div className="grid grid-cols-1 gap-2">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="">
                      <p className="text-sm font-semibold">CONNECTED DEVICE : </p>
                    </div>
                    <select onChange={handleSelectedDevice}>
                      <option name="" value="" key="select device">Select device</option>
                      {
                        devices && devices.map((device) => {
                          return (
                            <option name={device.deviceName} key={device.deviceName} value={device.deviceName} >{device.deviceName}</option>
                          )
                        }
                        )
                      }
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-2">
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <p className="text-sm font-semibold">SHEET TYPE : </p>
                    </div>
                    <select onChange={handleSheetTypeSelection}>
                      <option name="" value="" key="select sheet">Select sheet</option>
                      <option name="excel sheet" key="excel sheet" value="excel sheet" >Excel Sheet</option>
                      <option name="google sheet" key="google sheet" value="google sheet" >Google Sheet</option>
                    </select>
                  </div>
                </div>


                <div
                  className="grid grid-cols-1 gap-2"
                >
                  <div className="grid grid-cols-1 mt-4">
                    {
                      sheetTypeSelection === "google sheet" && (
                        <div className="flex justify-start">
                          <GoogleSheetHandler setExcelColumnValues={setExcelColumnValues} userProfile={userProfileInfo} />
                        </div>
                      )
                    }
                    {
                      sheetTypeSelection === "excel sheet" && (
                        <div className="flex gap-2 justify-start">
                          <ExcelSheetHandler setExcelColumnValues={setExcelColumnValues} />
                        </div>
                      )
                    }
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    {/* <label className="text-sm font-semibold" htmlFor="View Context">View Context</label> */}

                    {
                      excelColumnValues &&
                        excelColumnValues.name &&
                        excelColumnValues.phone &&
                        excelColumnValues.message ? (
                        <button className="bg-blue-400 px-2 py-1 text-xs w-[140px] rounded-md text-white" disabled={error !== ""} onClick={(e) => {
                          e.preventDefault();
                          setExcelSheetVisible(!excelSheetVisible)
                        }}>
                          <div className="flex justify-start items-center gap-2">
                            <View />
                            <span>View Contacts</span>
                          </div>
                        </button>
                      ) : (
                        <button className="px-2 py-1 text-xs w-[140px] rounded-md text-white disabled bg-neutral-500" disabled={error !== ""} >
                          <div className="flex justify-start items-center gap-2">
                            <View />
                            <span>Map All Columns</span>
                          </div>
                        </button>
                      )
                    }

                  </div>
                  <div>
                    {excelSheetVisible && <ExcelSheetView className="w-full" excelColumnValues={excelColumnValues} setExcelSheetValues={setExcelSheetValues} setExcelSheetVisible={setExcelSheetVisible} />}
                  </div>

                  <input autoComplete="chrome-off"
                    type="text"
                    name="phone"
                    id="phone"
                    className="w-full rounded-md px-2"
                    onChange={(e) => { e.preventDefault(); handleMessage(e) }}
                    ref={phoneRef}
                    disabled={isClicked}
                    readOnly
                  />

                  <div className="flex justify-between items-center">
                    <div>
                      <label
                        htmlFor="message"
                        className="text-sm font-serif font-semibold text-gray-600"
                      >

                        <span className="text-sm  text-blue-500 font-semibold">
                          Que : {totalMessage}
                        </span>{" "}
                        |{" "}
                        <span className="text-sm  text-green-500 font-semibold">
                          Sent : {sentMessage}
                        </span>
                      </label>
                    </div>
                    <div className="flex justify-start items-center space-x-2 my-2">
                      <label htmlFor="template" className="flex justify-start space-x-2 items-center text-gray-600">
                        <input type="radio" name="messageType" id="messageType" value="defaultMessage" onChange={(e) => {
                          e.preventDefault();
                          handleMessage(e)
                        }} checked={message.messageType === "defaultMessage"} />
                        <span>Default Message</span>
                      </label>
                      <label htmlFor="template" className="flex justify-start space-x-2 items-center text-gray-600">
                        <input type="radio" name="messageType" id="sheetMessage" value="sheetMessage" onChange={(e) => {
                          e.preventDefault()
                          handleMessage(e)
                        }} checked={message.messageType === "sheetMessage"} />
                        <span>Sheet Message</span>
                      </label>
                    </div>
                  </div>

                  <div className="w-full rounded-md p-2 bg-white h-[200px]">
                    <ReactQuill
                      style={{ height: '150px' }}
                      ref={quillRef}
                      name="message"
                      id="message"
                      onChange={handleQuillChange}
                      value={message?.message}
                      readOnly={isClicked || message.messageType === "sheetMessage"}
                      placeholder="Default Message"
                      theme="snow"
                    />
                  </div>

                  <label htmlFor="file" className="text-sm font-semibold text-gray-600 uppercase">
                    Attach File:
                  </label>
                  <input
                    type="file"
                    accept=".txt, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    name="file"
                    id="file"
                    onChange={changeHandler}
                    ref={fileRef}
                  />

                  <p className="text-xs font-semibold text-green-400 uppercase pb-2 ">About File</p>
                  <div>
                    <p>
                      Filename: {selectedFile.name ? selectedFile.name : ""}
                    </p>
                    <p>
                      Filetype: {selectedFile.type ? selectedFile.type : ""}
                    </p>
                    <p>
                      Size in bytes:{" "}
                      {selectedFile.size ? selectedFile.size : ""}
                    </p>
                    <p>
                      lastModifiedDate:{" "}
                      {selectedFile.lastModifiedDate
                        ? selectedFile.lastModifiedDate.toLocaleDateString()
                        : ""}
                    </p>
                  </div>


                  {isScheduleClicked && (
                    <div>
                      <label htmlFor="dateTime" className="text-sm font-semibold text-gray-600 text-center uppercase my-2">
                        Scheduled
                      </label>
                      <input
                        type="datetime-local"
                        name="dateTime"
                        id="dateTime"
                        className="w-full rounded-md p-2"
                        onChange={handleMessage}
                        ref={dateTimeRef}
                      />
                    </div>
                  )}

                  <div className="my-2">
                    {
                      status !== "" ? (
                        <p className="text-center text-sm tracking-normal text-green-500 ">
                          {
                            status && (<Alerts alertType="Info">{status}</Alerts>)
                          }
                        </p>
                      ) : error !== "" ? (
                        <p className="text-center text-sm tracking-normal text-red-500">
                          {
                            error && (<Alerts alertType="Error">{error}</Alerts>)
                          }
                        </p>
                      ) : (
                        ""
                      )
                    }
                  </div>

                  {
                    userProfileInfo.planType &&
                    (
                      userProfileInfo.planType.includes("GS") ||
                      userProfileInfo.planType.includes("WG") ||
                      userProfileInfo.planType.includes("RC")
                    ) && (<div className="p-2 text-right">
                      <button
                        className="rounded-md bg-orange-400 py-1 px-4 text-sm text-white"
                        onClick={sendMessage}
                        disabled={isClicked || isScheduleClicked || !isDeviceSelected || error !== ""}
                      >
                        {!isDeviceSelected ? "Select device" : isClicked ? "Sending..." : "Send Now"}
                      </button>
                      {!isScheduleClicked && (<button
                        className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-4"
                        onClick={() => setIsScheduleClicked(true)}
                        disabled={isClicked || isScheduleClicked || !isDeviceSelected || error !== ""}
                      >
                        Schedule
                      </button>)}
                      {isScheduleClicked && !isScheduleProcess && (<button
                        className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-4"
                        disabled={!dateTimeRef.current.value}
                        onClick={() => sendScheduleMessage()}
                      >
                        Set Schedule
                      </button>)}
                      {isScheduleClicked && isScheduleProcess && (<button
                        className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-4"
                      >
                        Please Wait...
                      </button>)}
                      <button
                        className="rounded-md bg-red-400 py-1 px-4 text-sm text-white ml-4"
                        onClick={resetAll}
                      >
                        Reset
                      </button>
                    </div>)
                  }

                </div>
              </div>
            </div>

            <div className="col-span-3">
              <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
            </div>
          </div>
        </form>
      </Container>
    </div >
  );
}

export default SheetMessage;
